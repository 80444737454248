import { GetStaticProps } from "next"

import { HomePage, HomePageProps } from "src/components/pages/home/HomePage"
import { AppLocale } from "src/config"
import { getHomePageNote } from "src/data/github"
import { getStats } from "src/data/metaData"
import { getVerseOfTheDay } from "src/data/verseOfTheDay"

export const getStaticProps: GetStaticProps<HomePageProps> = async (context) => {
  const locale = context.locale as AppLocale
  const verseOfTheDay = getVerseOfTheDay(locale)

  const [stats, note] = await Promise.all([getStats(), getHomePageNote(locale)])

  return {
    props: { stats, note, verseOfTheDay },
    revalidate: 10,
  }
}

export default HomePage
