import { useAuthorsVariant } from "src/components/state/authorsVariant"
import { useMounted } from "src/components/utils/useMounted"
import { appRoutes } from "src/config"

export function useAuthorsRoute() {
  const authorsVariant = useAuthorsVariant()
  const mounted = useMounted()

  return mounted && authorsVariant === "lastName-firstName" ? appRoutes.authorsReverse : appRoutes.authors
}
