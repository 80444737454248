import { FC } from "react"

import { TocListItem } from "src/components/pages/home/TocListItem"
import { useAuthorsRoute } from "src/components/pages/home/useAuthorsRoute"
import { useNewRoute } from "src/components/pages/home/useNewRoute"
import { routeTranslations } from "src/components/shared/routeTranslations"
import { useLocalizedText } from "src/components/state/localization"
import { appRoutes } from "src/config"

export const TocLinks: FC = () => {
  const T = useLocalizedText(routeTranslations)
  const authorsRoute = useAuthorsRoute()
  const newRoute = useNewRoute()

  return (
    <nav>
      <ul className="-ml-3 grid grid-cols-auto-2 justify-start gap-x-2 whitespace-nowrap">
        <TocListItem href={appRoutes.languages}>{T.languages}</TocListItem>
        <TocListItem href={authorsRoute}>{T.authors}</TocListItem>
        <TocListItem href={appRoutes.categories}>{T.categories}</TocListItem>
        <TocListItem href={appRoutes.bibleBooks}>{T.bibleBooks}</TocListItem>
        <TocListItem href={appRoutes.topics}>{T.topics}</TocListItem>
        <TocListItem href={newRoute}>{T.new}</TocListItem>
      </ul>
    </nav>
  )
}
