import { FC } from "react"

import { HorizontalSpacing } from "src/components/generic/HorizontalSpacing"
import { VerseOfTheDay } from "src/components/pages/home/VerseOfTheDay"
import { MetaTags } from "src/components/shared/MetaTags"
import { SimpleSearchForm } from "src/components/shared/SimpleSearchForm"
import { useLocalizedText } from "src/components/state/localization"
import { appConfig } from "src/config"
import { SharedPageProps, VerseOfTheDayData } from "src/types/app"

import { TocLinks } from "./TocLinks"

export type HomePageProps = SharedPageProps & {
  note: string
  verseOfTheDay: VerseOfTheDayData | null
}

export const HomePage: FC<HomePageProps> = ({ note, verseOfTheDay }) => {
  const T = useLocalizedText({ de, en })

  return (
    <>
      <MetaTags />

      <HorizontalSpacing as="main">
        <div className="grid gap-pageY">
          <div className="grid gap-1 lg:my-pageY">
            <h1 className="Heading-lg">{appConfig.siteName}</h1>
            <span className="Heading-md">{T.slogan}</span>
          </div>

          <SimpleSearchForm />

          <div className="row-start-2 grid">
            <TocLinks />
          </div>

          {note && <div className="Markdown" dangerouslySetInnerHTML={{ __html: note }} />}

          {verseOfTheDay && (
            <div className="Markdown">
              <VerseOfTheDay verseOfTheDay={verseOfTheDay} />
            </div>
          )}
        </div>
      </HorizontalSpacing>
    </>
  )
}

const de = {
  slogan: "Die Predigt-Datenbank",
}

const en: typeof de = {
  slogan: "The Sermon Database",
}
