import { create as createStore } from "zustand"
import { persist } from "zustand/middleware"

export const authorsVariants = ["firstName-lastName", "lastName-firstName"] as const

export type AuthorsVariant = (typeof authorsVariants)[number]

type State = {
  variant: AuthorsVariant
}

const defaultState: State = {
  variant: "firstName-lastName",
}

const store = createStore(persist(() => defaultState, { name: "authors-variant" }))

export function setAuthorsVariant(variant: AuthorsVariant) {
  store.setState({ variant })
}

export function useAuthorsVariant() {
  return store((state) => state.variant)
}
