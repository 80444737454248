import { FC } from "react"

import { A } from "src/components/generic/A"
import { useLocalizedText } from "src/components/state/localization"
import { formatQueryUrl } from "src/components/utils/links"
import { VerseOfTheDayData } from "src/types/app"

export type VerseOfTheDayProps = {
  verseOfTheDay: VerseOfTheDayData
}

export const VerseOfTheDay: FC<VerseOfTheDayProps> = ({ verseOfTheDay }) => {
  const T = useLocalizedText({ de, en })

  return (
    <div className="grid gap-2">
      <h2 className="font-semibold">
        {T.heading} {verseOfTheDay.todayFormatted}:
      </h2>

      <div>
        <p>{verseOfTheDay.oldTestamentText}</p>
        <p>
          <A href={formatQueryUrl(verseOfTheDay.oldTestamentRef)}>{verseOfTheDay.oldTestamentRef}</A>
        </p>
      </div>

      <div>
        <p>{verseOfTheDay.newTestamentText}</p>
        <p>
          <A href={formatQueryUrl(verseOfTheDay.newTestamentRef)}>{verseOfTheDay.newTestamentRef}</A>
        </p>
      </div>

      <div className="text-right text-sm">
        {verseOfTheDay.source} · <A href={verseOfTheDay.infoUrl}>{T.furtherInformation}</A>
      </div>
    </div>
  )
}

const de = {
  heading: "Losung und Lehrtext für",
  furtherInformation: "Weitere Informationen",
}

const en: typeof de = {
  heading: "Verse of the Day for",
  furtherInformation: "Further information",
}
