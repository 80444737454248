import { FC, KeyboardEventHandler, useRef } from "react"

import { Icon } from "src/components/generic/Icon"
import { useLocalizedText } from "src/components/state/localization"

export type SearchInputProps = {
  value: any
  onChange: (value: string) => void
  onSubmit: () => void
}

export const SearchInput: FC<SearchInputProps> = ({ value, onChange, onSubmit }) => {
  const T = useLocalizedText({ de, en })
  const inputRef = useRef<HTMLInputElement>(null)

  const handleKeydown: KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter" && !e.defaultPrevented) {
      onSubmit?.()
      inputRef.current?.blur()
    }
  }

  return (
    <div className="relative max-w-2xl">
      <input
        ref={inputRef}
        role="searchbox"
        autoComplete="off"
        name="sermon-online"
        placeholder={T.placeholder}
        enterKeyHint="search"
        className="h-control-lg pr-control-lg"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={handleKeydown}
      />

      <div className="absolute right-0 top-0 h-control-lg w-control-lg p-1">
        <button
          type="button"
          onClick={onSubmit}
          aria-label={T.submit}
          className="Button h-full min-h-0 w-full min-w-0 border-none p-0"
        >
          <Icon name="search" className="h-icon w-icon" />
        </button>
      </div>
    </div>
  )
}

const de = {
  placeholder: "Suche nach Titel, Bibelstelle, Stichwort, Autor...",
  submit: "Suche starten",
}

const en: typeof de = {
  placeholder: "Search by title, verse, keyword, author...",
  submit: "Submit search",
}
