import { FC, ReactNode } from "react"

import { A } from "src/components/generic/A"
import { Icon } from "src/components/generic/Icon"

export type TocListItemProps = {
  href: string
  children: ReactNode
}

export const TocListItem: FC<TocListItemProps> = ({ href, children }) => {
  return (
    <li key={href} className="grid md:w-40 fine:inline-flex">
      <A
        href={href}
        className="grid w-full grid-flow-col items-center gap-2 p-2 font-semibold text-gray-900 dark:text-white"
      >
        <Icon name="bookmark" className="h-icon w-icon" /> {children}
      </A>
    </li>
  )
}
