import { useRouter } from "next/router"

import { AppLocale, SortOrder, appRoutes, localeToLanguageIdMap } from "src/config"

export function useNewRoute() {
  const router = useRouter()

  const order: SortOrder = "dateAdded"
  const orderParam = `?order=${order}`

  const language =
    router.locale && router.locale !== router.defaultLocale && router.locale in localeToLanguageIdMap
      ? localeToLanguageIdMap[router.locale as AppLocale]
      : ""
  const languageParam = language ? `&language=${language}` : ""

  return appRoutes.new + orderParam + languageParam
}
